import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { OrangeTitle } from "./../../../css/Titre.module.css"

import Button from "./../../../components/Button"
import Popup from "./../../../components/Popup"

import Edit from "./../../../res/icons/edit.png"
import Trash from "./../../../res/icons/trash.png"

import firebase from "gatsby-plugin-firebase"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Wrapper = styled.section`
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
`

const ButtonWrapper = styled.section`
  margin-top: 30px;
  width: 150px;
  height: 40px;
`

const TableWrapper = styled.section`
  padding-top: 30px;
  overflow-x: scroll;
`

const HeaderWrapper = styled.section`
  width: 2850px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
`

const MajorLineWrapper = styled.section`
  margin-top: 20px;
  width: 2850px;
  height: 0.1em;
  background-color: #ff8000;
`

const JobsWrapper = styled.section`
  padding-bottom: 20px;
`

const JobWrapper = styled.section`
  padding-top: 20px;
  width: 2850px;
  display: flex;
  flex-direction: row;
`

const InfoWrapper = styled.section`
  width: ${props => props.width}px;

  & > a {
    color: #444444;
    text-decoration: none;

    :hover {
      color: #444444;
      text-decoration: underline;
    }
  }
`

const MinorLineWrapper = styled.section`
  margin-top: 20px;
  width: 2850px;
  height: 1px;
  background-color: #ff8000;
`

const Jobs = ({ jobs }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [trashPopupIsOpened, setTrashPopupIsOpened] = useState(false)
  const [jobTrashSelected, setJobTrashSelected] = useState(0)

  const toggleTrashPopup = idx => {
    setJobTrashSelected(idx)
    setTrashPopupIsOpened(!trashPopupIsOpened)
  }

  const handleDeleteShop = () => {
    toggleTrashPopup()
    firebase
      .firestore()
      .collection("jobs")
      .doc(jobs[jobTrashSelected].id)
      .delete()
      .then(() => {
        setSuccessSnackbarIsOpened(true)
      })
      .catch(error => {
        //console.log("error when deleting: ", error)
      })
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const HeaderJobsTable = () => {
    return (
      <HeaderWrapper>
        <p style={{ width: "300px" }}>Titre</p>
        <p style={{ width: "200px" }}>Partenaire</p>
        <p style={{ width: "300px" }}>Description</p>
        <p style={{ width: "200px" }}>Contrat</p>
        <p style={{ width: "200px" }}>Début</p>
        <p style={{ width: "200px" }}>Durée</p>
        <p style={{ width: "300px" }}>Niveau d'études</p>
        <p style={{ width: "300px" }}>Compétences</p>
        <p style={{ width: "200px" }}>Salaire</p>
        <p style={{ width: "250px" }}>Moyen pour postuler</p>
        <p style={{ width: "200px" }}>Masquée</p>
        <p style={{ width: "200px" }}>Candidats</p>
        <p style={{ width: "100px" }}>Modifier</p>
        <p style={{ width: "100px" }}>Supprimer</p>
      </HeaderWrapper>
    )
  }

  const JobsInfosInTable = ({ job, trashIsClicked }) => {
    const [shop, setShop] = useState("")

    const editIsClicked = () => {
      window.location.href = "/admin/jobs/modifier?id=" + job["id"]
    }

    useEffect(() => {
      firebase
        .firestore()
        .collection("shops")
        .doc(job["shopId"])
        .get()
        .then(doc => {
          setShop(
            doc._delegate._document.data.value.mapValue.fields.name.stringValue
          )
        })
        .catch(error => {
          //console.log("Error getting document:", error)
        })
    }, [shop])

    return (
      <JobWrapper>
        <InfoWrapper width={300}>
          <p>{job.title}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <p>{shop}</p>
        </InfoWrapper>
        <InfoWrapper width={300}>
          <p>{job.description}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <p>{job.contract}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <p>{job.startDate}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <p>{job.duration}</p>
        </InfoWrapper>
        <InfoWrapper width={300}>
          <p>{job.studiesLevel}</p>
        </InfoWrapper>
        <InfoWrapper width={300}>
          <p>{job.competencies}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <p>{job.salaryRange}</p>
        </InfoWrapper>
        <InfoWrapper width={250}>
          <p>{job.wayToApply}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <p>{job.mask ? "Oui" : "Non"}</p>
        </InfoWrapper>
        <InfoWrapper width={200}>
          <a href={"/admin/jobs/candidates?id=" + job.id}>Voir</a>
        </InfoWrapper>
        <div style={{ width: "100px" }}>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={editIsClicked}
          >
            <img alt="edit" src={Edit} width="15px" height="15px" />
          </button>
        </div>
        <div style={{ width: "100px" }}>
          <button style={{ border: "none", backgroundColor: "transparent" }}>
            <img
              alt="trash"
              src={Trash}
              width="15px"
              height="15px"
              onClick={trashIsClicked}
            />
          </button>
        </div>
      </JobWrapper>
    )
  }

  return (
    <Wrapper>
      <h1 className={OrangeTitle}>Jobs</h1>
      <ButtonWrapper>
        <Button
          alt=""
          onClick={() => (window.location.href = "/admin/jobs/ajouter")}
        >
          Ajouter
        </Button>
      </ButtonWrapper>
      <TableWrapper>
        <HeaderJobsTable />
        <MajorLineWrapper></MajorLineWrapper>
        <JobsWrapper>
          {jobs.map((job, idx = 0) => (
            <>
              <JobsInfosInTable
                job={job}
                trashIsClicked={() => toggleTrashPopup(idx++)}
              />
              <MinorLineWrapper></MinorLineWrapper>
            </>
          ))}
        </JobsWrapper>
      </TableWrapper>
      {trashPopupIsOpened && (
        <Popup
          title={"Attention !"}
          onClose={() => toggleTrashPopup()}
          question={"Êtes-vous sûr de vouloir supprimer cette offre d'emploi ?"}
          onCancel={() => toggleTrashPopup()}
          onOk={() => handleDeleteShop()}
        />
      )}
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien supprimé cette offre d'emploi !
        </Alert>
      </Snackbar>
    </Wrapper>
  )
}

export default Jobs
