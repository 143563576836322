import React from "react"
import {
  PopupBox,
  Box,
  RowWrapper,
  Spacer,
  QuestionWrapper,
  ButtonWrapper,
} from "./Popup.module.css"
import Title from "./../../components/Title"
import Button from "./../../components/Button"
import Close from "./../../res/icons/close.png"

const Popup = props => {
  return (
    <div className={PopupBox}>
      <div className={Box}>
        <div className={RowWrapper}>
          <Title title={props.title} />
          <div className={Spacer}></div>
          <button
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={props.onClose}
          >
            <img
              src={Close}
              alt="close"
              style={{ paddingTop: "13px" }}
              width="20px"
              height="20px"
            />
          </button>
        </div>
        <p className={QuestionWrapper}>{props.question}</p>
        <div
          style={{
            paddingLeft: "calc((calc(80vw - 40px) / 2 - 165px))",
          }}
          className={RowWrapper}
        >
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => props.onCancel()}>
              Non
            </Button>
          </div>
          <div style={{ width: "30px" }}></div>
          <div className={ButtonWrapper}>
            <Button alt="" onClick={() => props.onOk()}>
              Oui
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
