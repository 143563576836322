import React from "react";
import { string } from "prop-types";

import { Wrapper, Line, TitleWrapper } from "./Title.module.css";
import { classNames } from "../../helpers/classNames";

const Title = ({ className, title }) => {
  return (
    <div className={classNames([Wrapper, className])}>
      <div className={Line} />
      <h2 className={TitleWrapper}>{title}</h2>
    </div>
  );
};

Title.propTypes = {
  title: string.isRequired,
  className: string,
};

Title.defaultProps = {
  className: "",
};

export default Title;
